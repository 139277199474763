import { type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import type { OperationalStatus } from '@lib/utilities/location-hours-utilities'
import { Typography } from '@shc/ui'

export interface LocationHoursSummaryProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'children'> {
  accessible?: boolean
  operationalStatus: OperationalStatus
  nextOpenTime?: string
  locationExceptionText?: string
}

const LocationHoursSummary = ({
  accessible,
  operationalStatus,
  nextOpenTime,
  locationExceptionText,
  className,
  ...props
}: LocationHoursSummaryProps) => {
  if (!operationalStatus || operationalStatus === 'Hidden') {
    return null
  }

  let operationalStatusColor
  if (!accessible) {
    switch (operationalStatus) {
      case 'Closed':
        operationalStatusColor = 'text-danger'
        break
      case 'Open':
      case 'Open 24 hours':
        operationalStatusColor = 'text-success'
        break
      default:
        break
    }
  }

  return (
    <div {...props}>
      <Typography className={clsx(operationalStatusColor, 'font-bold inline')}>
        {operationalStatus}
      </Typography>
      <Typography as="span" variant="body">
        {nextOpenTime && ` - ${nextOpenTime}`}
      </Typography>
      {locationExceptionText && (
        <Typography variant="body-semibold">{locationExceptionText}</Typography>
      )}
    </div>
  )
}

export default LocationHoursSummary
